/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import CalendarComponent from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";

import { getAllEvents } from "../network/ApiEvents";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activatedDate: new Date(new Date().setHours(0, 0, 0, 0)),
      todayDate: new Date(new Date().setHours(0, 0, 0, 0)),
      initialMonth: new Date().getMonth(),
      dateNow: new Date(new Date().setHours(0, 0, 0, 0)),
      events: [],
      eventsInActivatedDate: [],
      nextEvents: [],
      nextEventsLimitted: [],
      nextEventsLimit: 5,
    };
  }

  async componentDidMount() {
    await this.getEvents();
    if (this.state.events && this.state.events.length) {
      const eventsInActivatedDate = this.state.events.filter((event) => {
        if (event.calendar_dates && event.calendar_dates.length) {
          return (
            event.calendar_dates.indexOf(
              this.formatDate(this.state.todayDate)
            ) !== -1
          );
        }
        return false;
      });
      const nextEvents = [];
      this.state.events.filter((event) => {
        if (event.calendar_dates && event.calendar_dates.length) {
          const eventContainsAtLeastOneNextEvent = event.calendar_dates.filter(
            (eventCalendarDate) => {
              if (
                Date.parse(this.setUTCHours(eventCalendarDate)) >=
                Date.parse(this.state.todayDate)
              ) {
                nextEvents.push({ ...event, date: eventCalendarDate });
              }
            }
          );
          return eventContainsAtLeastOneNextEvent.length;
        }
        return false;
      });

      const nextEventsLimitted = nextEvents.slice(
        0,
        this.state.nextEventsLimit
      );
      this.setState({
        eventsInActivatedDate,
        nextEvents,
        nextEventsLimitted,
      });
    }
  }

  getEvents = async () => {
    try {
      // setLoading(true);
      const events = await getAllEvents();
      console.log(events);
      if (events.data) {
        this.state.events = events.data;
        console.log(events.data);
      }
      // setLoading(false);
    } catch (err) {
      console.log("err", err);
    }
  };

  handleChange = (date) => {
    this.setState({ activatedDate: date });
    const eventsInActivatedDate = this.state.events.filter((event) => {
      if (event.calendar_dates && event.calendar_dates.length) {
        return event.calendar_dates.indexOf(this.formatDate(date)) !== -1;
      }
      return false;
    });
    this.setState({ eventsInActivatedDate });
  };

  handleValue = () => {
    return this.state.dateNow;
  };

  handleActiveStartDateChange = (activeStartDate) => {
    const activeMonth = activeStartDate.getMonth();
    if (activeMonth === this.state.todayDate.getMonth()) {
      // active month equals date now month
      this.setState({ dateNow: this.state.todayDate });
    } else {
      this.setState({ dateNow: activeStartDate });
    }
  };

  setUTCHours = (date) => {
    return new Date(new Date(date).setUTCHours(3, 0, 0, 0));
  };

  formatDate = (date, format, toNewDate = false) => {
    if (toNewDate) {
      date = this.setUTCHours(date);
    }
    const activatedDate = date;
    const day =
      activatedDate.getDate() < 10
        ? `0${activatedDate.getDate()}`
        : activatedDate.getDate();
    const month =
      activatedDate.getMonth() + 1 < 10
        ? `0${activatedDate.getMonth() + 1}`
        : activatedDate.getMonth() + 1;
    const year = activatedDate.getFullYear();
    if (format === "br") {
      return `${day}/${month}/${year}`;
    } else {
      return `${year}-${month}-${day}`;
    }
  };

  handleCalendarTile = (activeStartDate, date) => {
    const activeMonth = activeStartDate.getMonth();
    const dateFormatted = this.formatDate(date);
    if (
      this.state.events.find((event) => {
        if (event.calendar_dates && event.calendar_dates.length) {
          return event.calendar_dates.indexOf(dateFormatted) !== -1;
        }
        return false;
      })
    ) {
      // day with any event
      if (date.getMonth() === activeMonth) {
        // day in actual month
        if (date.getTime() === this.state.dateNow.getTime()) {
          // day now tile
          return "bg-red";
        }
        return "bg-purple text-default";
      }
      // day in neighboring month
      return "bg-blue text-gray";
    }
    if (date.getMonth() !== activeMonth) {
      // day in neighboring month
      return "opacity-5";
    }
    return;
  };

  render() {
    return (
      <>
        <Header showCards />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col
              className="mb-5 mb-xl-0 mt-5 mt-xl-0"
              xl={{ size: 8, order: 1 }}
              xs={{ size: 12, order: 2 }}
            >
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Calendário</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Row>
                  {/* ['xs', 'sm', 'md', 'lg', 'xl'] */}
                  <Col xl="7">
                    <Calendar
                      view={"month"}
                      locale={"pt-BR"}
                      minDetail={"month"}
                      onChange={(date) => this.handleChange(date)}
                      defaultValue={this.handleValue()}
                      onActiveStartDateChange={({ activeStartDate }) =>
                        this.handleActiveStartDateChange(activeStartDate)
                      }
                      tileClassName={({ activeStartDate, date }) =>
                        this.handleCalendarTile(activeStartDate, date)
                      }
                    />
                  </Col>
                  <Col xl="5">
                    {this.state.activatedDate && (
                      <div>
                        <h2>
                          {this.formatDate(this.state.activatedDate, "br")}
                        </h2>
                        {this.state.eventsInActivatedDate &&
                        this.state.eventsInActivatedDate.length > 0 ? (
                          <ul>
                            {this.state.eventsInActivatedDate.map(
                              (event, index) => {
                                return <li key={index}>{`${event.name}`}</li>;
                              }
                            )}
                          </ul>
                        ) : (
                          "Nenhum evento nessa data"
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xl={{ size: 4, order: 2 }} xs={{ size: 12, order: 1 }}>
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        Próximos eventos
                        {this.state.nextEventsLimitted.length <
                        this.state.nextEvents.length ? (
                          <p className="m-0">
                            Mostrando {this.state.nextEventsLimitted.length} de{" "}
                            {this.state.nextEvents.length}
                          </p>
                        ) : (
                          ""
                        )}
                      </h3>
                    </div>
                    {this.state.nextEventsLimitted.length <
                    this.state.nextEvents.length ? (
                      <div className="col text-right">
                        <Button
                          color="primary"
                          href="#"
                          onClick={(e) => e.preventDefault()}
                          size="sm"
                        >
                          Ver todos
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </Row>
                </CardHeader>

                {this.state.nextEventsLimitted &&
                this.state.nextEventsLimitted.length > 0 ? (
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    style={{ tableLayout: "fixed" }}
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Nome do evento</th>
                        <th scope="col">Data</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.nextEventsLimitted.map((nextEvent, index) => {
                        return (
                          <tr key={index}>
                            <th
                              scope="row"
                              style={{
                                whiteSpace: "break-spaces",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {nextEvent.name}
                            </th>
                            <td>
                              {this.formatDate(nextEvent.date, "br", true)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <p className="text-center">Não há próximos eventos</p>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Index;

export const Calendar = styled(CalendarComponent)`
  width: 100%;
  border: 0;
`;
