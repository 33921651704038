import axios from "axios";
import config from "../config";

// const https = require('https');
//
// const agent = new https.Agent({
//     rejectUnauthorized: false,
// });

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});

export const getAllEvents = async () => await instance.post("events/all");
export const getEvent = async (eventId) =>
  await instance.post(`events/get/${eventId}`);
export const addEvent = async (event) =>
  await instance.post("/events/create", event); // Ao invés de passar o userID, usar no userID que enviou a requisição para o backend
export const editEvent = async (event, eventId) =>
  await instance.post(`/events/edit/${eventId}`, event); // Ao invés de passar o userID, usar no userID que enviou a requisição para o backend
export const deleteEvent = async (eventId) =>
  await instance.delete(`/events/${eventId}`);

export const getAllEventsTypes = async () =>
  await instance.post("eventsTypes/allActivated");
export const getAllEventsCategories = async () =>
  await instance.post("eventsCategories/allActivated");
export const getAllEventsCoverages = async () =>
  await instance.post("eventsCoverages/allActivated");
export const getAllEventsExpensesTypes = async () =>
  await instance.post("eventsExpensesTypes/allActivated");
export const getAllLecturers = async () =>
  await instance.post("lecturers/allActivated");
export const getAllOrganizers = async () =>
  await instance.post("organizers/allActivated");
