import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Modal,
} from "reactstrap";
import { FaPen, FaTrash, FaSpinner } from "react-icons/fa";
// core components
import Header from "components/Headers/Header.js";
import { Link, useHistory } from "react-router-dom";
import Button from "reactstrap/lib/Button";
import { getAllEvents, deleteEvent } from "../../network/ApiEvents";
import Col from "reactstrap/lib/Col";

import "./events.scss";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [eventToDelete, setEventToDelete] = useState();
  const [loading, setLoading] = useState(false);
  const [deletingEvent, setDeletingEvent] = useState(false);
  const history = useHistory();
  const eventsStatusToPreventUserAction = ["created", "correct", "finished"];

  function toggleModalOpened() {
    setModalOpened(!modalOpened);
  }

  function eventDisabled(event) {
    return (
      eventsStatusToPreventUserAction.indexOf(event.status) === -1 &&
      event.disabled
    );
  }

  async function handleDeleteEvent(event) {
    if (!eventDisabled(event)) {
      setModalOpened(true);
      setEventToDelete(event);
    }
  }

  async function deleteEventSelected() {
    if (!deletingEvent && eventToDelete) {
      try {
        setDeletingEvent(true);
        await deleteEvent(eventToDelete._id);
        setEvents(events.filter((ev) => ev._id !== eventToDelete._id));
        setModalOpened(false);
        setDeletingEvent(false);
      } catch (err) {
        console.log("err", err);
        setDeletingEvent(false);
        setModalOpened(false);
      }
      setEventToDelete();
    }
  }

  useEffect(() => {
    const getEvents = async () => {
      try {
        // setLoading(true);
        const events = await getAllEvents();
        console.log(events);
        if (events.data) {
          setEvents(events.data);
          console.log(events.data);
        }
        // setLoading(false);
      } catch (err) {
        console.log("err", err);
      }
    };
    getEvents();
  }, []);

  const handleEditEvent = (event) => {
    history.push(`/admin/events/edit/${event._id}`);
  };

  const translateEventStatus = (status, type = "status") => {
    let badgeName = null;
    let badgeIcon = null;
    switch (status) {
      case "created": // Criado
        badgeName = "Criado";
        badgeIcon = "bg-blue";
        break;
      case "revision": // Em revisão
        badgeName = "Em revisão";
        badgeIcon = "bg-yellow";
        break;
      case "correct": // Corrigir
        badgeName = "Corrigir";
        // badgeIcon = "bg-indigo";
        badgeIcon = "bg-dark";
        break;
      case "refused": // Recusado
        badgeName = "Recusado";
        badgeIcon = "bg-danger";
        break;
      case "approved": // Aprovado
        badgeName = "Aprovado";
        // badgeIcon = "bg-cyan";
        badgeIcon = "bg-indigo";
        break;
      case "finished": // Finalizado
        badgeName = "Finalizado";
        badgeIcon = "bg-success";
        break;
    }
    return type === "badge" ? badgeIcon : badgeName;
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <>
          {loading && (
            <div className="loading-container">
              <FaSpinner className="spinner" />
              <p className="m-0">Carregando eventos</p>
            </div>
          )}
        </>
        <Row>
          {/* Table */}
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">
                      Eventos{" "}
                      {!loading
                        ? `(${events && events.length ? events.length : 0})`
                        : ""}
                    </h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Link to={`/admin/events/add`}>
                      <Button color="primary">Novo evento</Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nome do evento</th>
                    <th scope="col">Data</th>
                    <th scope="col">Status</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {events && events.length ? (
                    events.map((event) => {
                      return (
                        <tr
                          key={event._id}
                          className={
                            eventDisabled(event) ? "opacity-5 bg-lighter" : ""
                          }
                        >
                          <th scope="row">
                            <span className="mb-0 text-sm">
                              {event.name || ""}
                            </span>
                          </th>
                          <td>{event.date || ""}</td>
                          <td>
                            <Badge color="" className="badge-dot mr-4">
                              <i
                                className={translateEventStatus(
                                  event.status,
                                  "badge"
                                )}
                              />
                              {translateEventStatus(event.status)}
                            </Badge>
                          </td>
                          <td className="text-right">
                            <Button
                              className="mr-2"
                              color="success"
                              disabled={eventDisabled(event)}
                              onClick={(e) =>
                                eventDisabled(event)
                                  ? e.preventDefault()
                                  : handleEditEvent(event)
                              }
                            >
                              <FaPen />
                            </Button>
                            <Button
                              color="danger"
                              onClick={() => handleDeleteEvent(event)}
                              disabled={eventDisabled(event)}
                            >
                              <FaTrash />
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>Nenhum evento encontrado</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered modal-danger"
        contentClassName="bg-gradient-danger"
        isOpen={modalOpened && !!eventToDelete}
        toggle={() => toggleModalOpened()}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalOpened(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x" />
            <h4 className="heading mt-4">
              Você tem certeza que deseja deletar este evento? Essa ação é
              irreversível!
            </h4>
            {eventToDelete && (
              <div>
                <p className="m-0 mt-4">
                  <strong>Nome do evento: </strong>
                  {eventToDelete.name}
                </p>
                <p className="m-0">
                  <strong>Status: </strong>
                  {translateEventStatus(eventToDelete.status)}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <Button
            className="btn-white"
            color="default"
            type="button"
            onClick={() => deleteEventSelected()}
            disabled={deletingEvent}
          >
            {deletingEvent ? "Deletando..." : "Deletar"}
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalOpened(false)}
          >
            Cancelar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Events;
