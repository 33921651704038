/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";

// core components
// import AuthNavbar from "components/Navbars/AuthNavbar.js";
// import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <div className="main-content">
          {/* <AuthNavbar /> */}
          <div className="header bg-gradient-info py-6">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    {/* <LogoImage
                      alt="..."
                      src={require("assets/img/brand/argon-react.png").default}
                    /> */}
                    <h1 className="text-white">Bem-vindo(a)!</h1>
                    <p className="text-lead text-light">
                      Faça seu login no formulário abaixo.
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                {/* linear-gradient(to right,#c93c25 0,#c3232a 100%) !important */}
                {/* linear-gradient(to right, #4c4f9c 0, #3d3f7d 100%) !important */}
                {/* <defs>
                  <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style="stop-color: #4c4f9c;stop-opacity:1;"></stop>
                    <stop offset="100%" style="stop-color: #3d3f7d;stop-opacity:1;"></stop>
                  </linearGradient>
                  <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style="stop-color: #c93c25;stop-opacity:1;"></stop>
                    <stop offset="100%" style="stop-color: #c3232a;stop-opacity:1;"></stop>
                  </linearGradient>
                </defs> */}
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                  // fill="url(#grad1)"
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from="*" to="/auth/login" />
              </Switch>
            </Row>
          </Container>
        </div>
        {/* <AuthFooter /> */}
      </>
    );
  }
}

export default Auth;

const LogoImage = styled.img`
  width: 100%;
  max-width: 200px;
  margin-bottom: 30px;
`;
