import styled from "styled-components";

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px dashed #ddd;
  border-radius: 5px;

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    font-size: 20px;
  }

  strong {
    font-size: 14px;
    margin-left: 3px;
  }

  button {
    font-size: 13px;
    border: 0;
    background: transparent;
    color: #f5365c;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px 10px;
    transition: 0.2s ease all;

    &:hover {
      color: #fff;
      background: #f5365c;
    }
  }
`;
