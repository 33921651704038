import React, { useState, useEffect } from "react";

import { Link, useHistory, useParams } from "react-router-dom";

import { uniqueId } from "lodash";
import filesize from "filesize";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  CardBody,
  Form,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  InputGroup,
  Alert,
  Collapse,
  Table,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import {
  FaPlus,
  FaSpinner,
  FaTrash,
  FaClock,
  FaRegClock,
} from "react-icons/fa";
import {
  getEvent,
  getAllEventsTypes,
  getAllEventsCategories,
  getAllEventsCoverages,
  getAllEventsExpensesTypes,
  getAllLecturers,
  getAllOrganizers,
  addEvent,
  editEvent,
} from "../../network/ApiEvents";

import CurrencyInput from "react-currency-input-field";

import "./eventsDetails.scss";
import "react-circular-progressbar/dist/styles.css";
import Upload from "../../components/Upload";
import FileItem from "../../components/FileItem";
// import FileList from "../../components/FileList";

const filesURL = "/files";

const EventsDetails = () => {
  const { id: eventId } = useParams();
  const history = useHistory();

  const [loadingEvent, setLoadingEvent] = useState(true);
  const [formDisabled, setFormDisabled] = useState(false);
  const [event, setEvent] = useState({});

  const [histories, setHistories] = useState([]);
  const [historiesOpened, setHistoriesOpened] = useState(false);

  const [status, setStatus] = useState("created");
  const [name, setName] = useState();
  const [typeId, setTypeId] = useState();
  const [category, setCategory] = useState();
  const [categoryId, setCategoryId] = useState();
  const [coverage, setCoverage] = useState();
  const [coverageId, setCoverageId] = useState();

  const [eventsTypes, setEventsTypes] = useState([]);

  const [eventsCategories, setEventsCategories] = useState([]);
  const [hasCategorySecondField, setHasCategorySecondField] = useState(false);
  const [categorySecondFieldName, setCategorySecondFieldName] = useState();
  const [categorySecondFieldValue, setCategorySecondFieldValue] = useState();

  const [eventsCoverages, setEventsCoverages] = useState([]);
  const [hasCoverageSecondField, setHasCoverageSecondField] = useState(false);
  const [coverageSecondFieldName, setCoverageSecondFieldName] = useState();
  const [coverageSecondFieldValue, setCoverageSecondFieldValue] = useState();

  const [dates, setDates] = useState([]);
  // const [workload, setWorkload] = useState();
  // const [place, setPlace] = useState();
  // const [audienceEstimate, setAudienceEstimate] = useState();
  // const [onlineEvent, setOnlineEvent] = useState(false);
  // const [link, setLink] = useState();
  // const [ticket, setTicket] = useState();
  const [objective, setObjective] = useState();
  const [reason, setReason] = useState();
  // const [schedule, setSchedule] = useState();
  // const [details, setDetails] = useState();
  // const [resources, setResources] = useState();

  const [lecturers, setLecturers] = useState([]);
  const [filteredLecturers, setFilteredLecturers] = useState([]);
  const [lecturersFetched, setLecturersFetched] = useState([]);
  const [lecturersFilteredIndex, setLecturersFilteredIndex] = useState();

  const [organizers, setOrganizers] = useState([]);
  const [filteredOrganizers, setFilteredOrganizers] = useState([]);
  const [organizersFetched, setOrganizersFetched] = useState([]);
  const [organizersFilteredIndex, setOrganizersFilteredIndex] = useState();

  const [expenses, setExpenses] = useState([]);
  const [expensesTypes, setExpensesTypes] = useState([]);

  const [receiptAmount, setReceiptAmount] = useState();
  const [totalAmount, setTotalAmount] = useState();

  const handleName = (eventName) => {
    setName(eventName);
  };

  const handleTypeId = (typeId) => {
    const type = eventsTypes.find((event) => event._id === typeId);
    setTypeId(type);
  };

  const handleCategoryChange = (categoryId) => {
    const eventCategory = eventsCategories.find(
      (event) => event._id === categoryId
    );
    setCategoryId(categoryId);
    if (hasSecondField(eventCategory)) {
      setHasCategorySecondField(true);
      setCategorySecondFieldName(eventCategory.secondary_field_name);
      // TODO: Add field required??
    } else {
      setHasCategorySecondField(false);
      setCategorySecondFieldName(null);
      // setCategorySecondFieldValue(null); // Clear category second field value
    }
  };

  const handleCategorySecondFieldValue = (value) => {
    if (!value) {
      value = null;
    }
    setCategorySecondFieldValue(value);
  };

  const handleCoverageChange = (coverageId) => {
    const eventCoverage = eventsCoverages.find(
      (event) => event._id === coverageId
    );
    setCoverageId(coverageId);
    if (hasSecondField(eventCoverage)) {
      setHasCoverageSecondField(true);
      setCoverageSecondFieldName(eventCoverage.secondary_field_name);
      // TODO: Add field required??
    } else {
      setHasCoverageSecondField(false);
      setCoverageSecondFieldName(null);
      // setCoverageSecondFieldValue(null); // Clear coverage second field value
    }
  };

  const handleCoverageSecondFieldValue = (value) => {
    if (!value) {
      value = null;
    }
    setCoverageSecondFieldValue(value);
  };

  const handleNewDate = () => {
    const _id = getNextIdFromArray(dates);
    setDates([
      ...dates,
      {
        _id,
        date: null,
        start_time: null,
        end_time: null,
        workload: null,
        place: null,
        audience_estimate: null,
        online: false,
        link: null,
        ticket: null,
        schedule: null,
        details: null,
        resources: null,
      },
    ]);
    // TODO: Set focus on date input
  };

  const handleDateData = (fieldName, dateId, value) => {
    if (dateId) {
      const newDates = dates.map((date) => {
        if (date._id === dateId) {
          date[fieldName] = value;
        }
        return date;
      });
      setDates(newDates);
    }
  };

  const handleDeleteDate = (dateId) => {
    const newDates = dates.filter((date) => date._id !== dateId);
    setDates(newDates);
  };

  const handleNewLecturer = () => {
    const _id = getNextIdFromArray(lecturers);
    setLecturers([
      ...lecturers,
      {
        _id,
        name: null,
        office: null,
        lattes: null,
        email: null,
        guest: false,
        dates: [],
      },
    ]);
  };

  const handleLecturer = (
    lecturerId,
    lecturerFieldName,
    lecturerFieldValue,
    lecturerFieldIndex = false
  ) => {
    const newLecturers = lecturers;
    const lectIndex = lecturers.findIndex(
      (lecturer) => lecturer._id === lecturerId
    );
    if (newLecturers && newLecturers[lectIndex]) {
      if (lecturerFieldName === "guest") {
        lecturerFieldValue = lecturerFieldValue.checked;
      } else if (lecturerFieldName === "dates") {
        lecturerFieldValue = lecturerFieldValue.checked;
      }
      if (lecturerFieldIndex !== false) {
        if (typeof newLecturers[lectIndex][lecturerFieldName] === "undefined") {
          newLecturers[lectIndex][lecturerFieldName] = [];
        }
        newLecturers[lectIndex][lecturerFieldName][lecturerFieldIndex] =
          lecturerFieldValue;
      } else {
        newLecturers[lectIndex][lecturerFieldName] = lecturerFieldValue;
      }
      setLecturers(newLecturers);
    }
  };

  const handleLecturerName = (lecturerId, value) => {
    const lecturerName = value ?? "";
    const lect = lecturers.find((lecturer) => lecturer._id === lecturerId);
    setLecturersFilteredIndex(lecturerId);
    if (lecturers && lect) {
      lect.name = lecturerName;
    }
    if (lecturerName) {
      const filteredLecturers = lecturersFetched.filter(
        (lecturer) =>
          lecturer.name.toLowerCase().indexOf(lecturerName.toLowerCase()) > -1
      );
      setFilteredLecturers(filteredLecturers);
    } else {
      setFilteredLecturers([]);
    }
  };

  const handleFilteredLecturerSelected = async (
    lecturerId,
    selectedLecturer
  ) => {
    const { name, office, lattes, email } = selectedLecturer;

    const lectIndex = lecturers.findIndex(
      (lecturer) => lecturer._id === lecturerId
    );
    const lecturerSelected = lecturers[lectIndex];
    if (lecturerSelected) {
      lecturerSelected.name = name;
      lecturerSelected.office = office;
      lecturerSelected.lattes = lattes;
      lecturerSelected.email = email;

      const lecturersWithoutSelected = lecturers.filter(
        (lect) => lect._id !== lecturerSelected._id
      );
      const newLecturers = lecturersWithoutSelected;

      await setLecturers(lecturersWithoutSelected);
      newLecturers.splice(lectIndex, 0, lecturerSelected);
      setLecturers(newLecturers);

      setFilteredLecturers([]);
    }
  };

  const handleDeleteLecturer = (lecturerId) => {
    const newLecturers = lecturers.filter(
      (lecturer) => lecturer._id !== lecturerId
    );
    setLecturers(newLecturers);
  };

  const handleNewOrganizer = () => {
    const _id = getNextIdFromArray(organizers);
    setOrganizers([
      ...organizers,
      {
        _id,
        name: null,
        identification: null,
        office: null,
        email: null,
        workload: null,
      },
    ]);
  };

  const handleOrganizer = (
    organizerId,
    organizerFieldName,
    organizerFieldValue
  ) => {
    const newOrganizers = organizers;
    const orgIndex = organizers.findIndex(
      (organizer) => organizer._id === organizerId
    );
    if (newOrganizers && newOrganizers[orgIndex]) {
      if (organizerFieldName === "workload") {
        organizerFieldValue = parseFloat(organizerFieldValue);
      }
      newOrganizers[orgIndex][organizerFieldName] = organizerFieldValue;
      setOrganizers(newOrganizers);
    }
  };

  const handleOrganizerName = (organizerId, value) => {
    const organizerName = value ?? "";
    const org = organizers.find((organizer) => organizer._id === organizerId);
    setOrganizersFilteredIndex(organizerId);
    if (organizers && org) {
      org.name = organizerName;
    }
    if (organizerName) {
      const filteredOrganizers = organizersFetched.filter(
        (organizer) =>
          organizer.name.toLowerCase().indexOf(organizerName.toLowerCase()) > -1
      );
      setFilteredOrganizers(filteredOrganizers);
    } else {
      setFilteredOrganizers([]);
    }
  };

  const handleFilteredOrganizerSelected = async (
    organizerId,
    selectedOrganizer
  ) => {
    const { name, identification, office, email } = selectedOrganizer;

    const orgIndex = organizers.findIndex(
      (organizer) => organizer._id === organizerId
    );
    const organizerSelected = organizers[orgIndex];
    if (organizerSelected) {
      organizerSelected.name = name;
      organizerSelected.identification = identification;
      organizerSelected.office = office;
      organizerSelected.email = email;

      const organizersWithoutSelected = organizers.filter(
        (org) => org._id !== organizerSelected._id
      );
      const newOrganizers = organizersWithoutSelected;

      await setOrganizers([]);
      newOrganizers.splice(orgIndex, 0, organizerSelected);
      setOrganizers(newOrganizers);

      setFilteredOrganizers([]);
    }
  };

  const handleDeleteOrganizer = (organizerId) => {
    const newOrganizers = organizers.filter(
      (organizer) => organizer._id !== organizerId
    );
    setOrganizers(newOrganizers);
  };

  const handleNewExpense = () => {
    const _id = getNextIdFromArray(expenses);
    setExpenses([
      ...expenses,
      {
        _id,
        event_expense_type_id: null,
        provider: null,
        amount: 0,
        file: null,
        filename: null,
        file_base64: null,
        file_uploaded: false,
        comments: null,
        // is_new: true,
        is_new: false,
      },
    ]);
  };

  const handleExpense = (expenseId, expenseFieldName, expenseFieldValue) => {
    const newExpenses = expenses;
    const expIndex = expenses.findIndex((expense) => expense._id === expenseId);
    if (newExpenses && newExpenses[expIndex]) {
      if (expenseFieldName === "file") {
        expenseFieldValue = expenseFieldValue[0]; // Get first file
      }
      newExpenses[expIndex][expenseFieldName] = expenseFieldValue;
      setExpenses(newExpenses);
    }
  };

  const handleDeleteExpense = (expenseId) => {
    const newExpenses = expenses.filter((expense) => expense._id !== expenseId);
    setExpenses(newExpenses);
  };

  const organizersIdentifications = [
    "Acadêmico",
    "Docente",
    "Profissional",
    "Outro",
  ];

  const organizersOffices = [
    "Expositor",
    "Extensionista",
    "Mediador",
    "Mestre de Cerimônias",
    "Oficineiro",
    "Organizador",
    "Outro",
  ];

  useEffect(() => {
    const fetchEventDetails = async () => {
      setLoadingEvent(true);

      let eventPromise = null;
      if (eventId) {
        try {
          eventPromise = await getEvent(eventId);
        } catch (e) {
          // TODO: Show notification error
          // if (e.response?.data?.msg) {
          //   alert(e.response?.data?.msg);
          // }
          return history.push("/admin/events");
        }
      }

      if (!eventId || (eventId && isValidResponse(eventPromise))) {
        const eventsTypesPromise = await getAllEventsTypes();
        if (isValidResponse(eventsTypesPromise)) {
          setEventsTypes(eventsTypesPromise.data);
        }
        const eventsCategoriesPromise = await getAllEventsCategories();
        if (isValidResponse(eventsCategoriesPromise)) {
          setEventsCategories(eventsCategoriesPromise.data);
        }
        const eventsCoveragesPromise = await getAllEventsCoverages();
        if (isValidResponse(eventsCoveragesPromise)) {
          setEventsCoverages(eventsCoveragesPromise.data);
        }
        const eventsExpensesTypesPromise = await getAllEventsExpensesTypes();
        if (isValidResponse(eventsExpensesTypesPromise)) {
          setExpensesTypes(eventsExpensesTypesPromise.data);
        }
        const lecturersPromise = await getAllLecturers();
        if (isValidResponse(lecturersPromise)) {
          setLecturersFetched(lecturersPromise.data);
        }
        const organizersPromise = await getAllOrganizers();
        if (isValidResponse(organizersPromise)) {
          setOrganizersFetched(organizersPromise.data);
        }

        if (eventId && isValidResponse(eventPromise)) {
          // editing event
          consoleLogWithColor(`eventId - ${eventId}`, "green", true);

          const evnt = eventPromise.data;
          setHistories(evnt.histories);
          setStatus(evnt.status);
          setName(evnt.name);
          setTypeId(evnt.event_type_id);
          setCategory(evnt.category_id);
          setCategorySecondFieldValue(evnt.category_second_field_value);
          setCoverage(evnt.coverage_id);
          setCoverageSecondFieldValue(evnt.coverage_second_field_value);
          setDates(evnt.dates);
          // setWorkload(evnt.workload);
          // setPlace(evnt.place);
          // setAudienceEstimate(evnt.audience_estimate);
          // setOnlineEvent(evnt.online);
          // setLink(evnt.link);
          // setTicket(evnt.ticket);
          setObjective(evnt.objective);
          setReason(evnt.reason);
          // setSchedule(evnt.schedule);
          // setDetails(evnt.details);
          // setResources(evnt.resources);
          setLecturers(evnt.lecturers);
          setOrganizers(evnt.organizers);
          setExpenses(evnt.expenses);
          setReceiptAmount(evnt.receipt_amount);
          setTotalAmount(evnt.total_amount);

          setLoadingEvent(false);
        } else {
          setLoadingEvent(false);
        }
      } else {
        // TODO: Show notification error
        return history.push("/admin/events");
      }
    };
    fetchEventDetails();
  }, []);

  useEffect(() => {
    if (eventsCategories.length) {
      handleCategoryChange(category);
    }
  }, [category]);

  useEffect(() => {
    if (eventsCoverages.length) {
      handleCoverageChange(coverage);
    }
  }, [coverage]);

  const hasSecondField = (firstField) => {
    return (
      firstField &&
      firstField.secondary_field === "1" &&
      firstField.secondary_field_name
    );
  };

  const getNextIdFromArray = (array) => {
    return array.length ? array[array.length - 1]._id + 1 : 1;
  };

  const formatToDecimalAndParseAmount = (value) => {
    if (value) {
      return parseFloat(value.replace(".", "").replace(",", "."));
    }
    return;
  };

  const formatDate = (date) => {
    const cleaned = ("" + date).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{4})(\d{2})(\d{2})$/);
    if (match) {
      return `${match[3]}/${match[2]}/${match[1]}`;
    }
    return date;
  };

  const isValidResponse = (response) => {
    if (response.status === 200 && response.data) {
      return true;
    }
    return false;
  };

  const generateEventDetails = () => {
    return {
      name,
      event_type_id: typeId,
      category: {
        id: categoryId,
        second_field_value: categorySecondFieldValue,
      },
      coverage: {
        id: coverageId,
        second_field_value: coverageSecondFieldValue,
      },
      dates,
      // workload,
      // place,
      // audience_estimate: audienceEstimate,
      // online: onlineEvent,
      // link,
      // ticket,
      objective,
      reason,
      // schedule,
      // details,
      // resources,
      lecturers,
      organizers,
      expenses,
      receipt_amount: receiptAmount,
      total_amount: totalAmount,
      status,
    };
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const eventObj = generateEventDetails();
    eventObj.status = status;
    console.table(eventObj);

    let response = null;
    try {
      if (eventId) {
        response = await editEvent(eventObj, eventId);
      } else {
        response = await addEvent(eventObj);
      }
      const { data } = response;
      if (data.success) {
        history.push("/admin/events");
      } else if (data.msg) {
        alert(data.msg);
      }
    } catch (err) {
      alert(
        err.response?.data?.msg ??
          "Erro não identificado. Contate o administrador do sistema."
      );
    }
  };

  const handleButtonSubmitted = async (event, status = "") => {
    event.preventDefault();
    const eventObj = generateEventDetails();
    eventObj.status = status;
    eventObj.send_to_review = status;
    console.table(eventObj);

    let response = null;
    try {
      if (eventId) {
        response = await editEvent(eventObj, eventId);
      } else {
        response = await addEvent(eventObj);
      }
      const { data } = response;
      if (data.success) {
        history.push("/admin/events");
      } else if (data.msg) {
        alert(data.msg);
      }
    } catch (err) {
      alert(
        err.response?.data?.msg ??
          "Erro não identificado. Contate o administrador do sistema."
      );
    }
  };

  const toggleHistoriesOpened = () => {
    setHistoriesOpened(!historiesOpened);
  };

  const handleUpload = async (expenseId, file) => {
    if (file && file.length) {
      const expenseSelected = expenses.find(
        (expense) => expense._id === expenseId
      );
      const expenseSelectedIndex = expenses.findIndex(
        (expense) => expense._id === expenseId
      );
      if (expenseSelected) {
        expenseSelected.file = file[0].name;
        expenseSelected.file_uploaded = false;
        expenseSelected.file_base64 = await getBase64(file[0]);
        const newExpenses = expenses.filter(
          (expense) => expense._id !== expenseId
        );
        newExpenses.splice(expenseSelectedIndex, 0, expenseSelected);
        setExpenses(newExpenses);
      }
    }
  };

  const getBase64 = async (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleDeleteFile = (expenseId) => {
    const expenseSelected = expenses.find(
      (expense) => expense._id === expenseId
    );
    const expenseSelectedIndex = expenses.findIndex(
      (expense) => expense._id === expenseId
    );
    if (expenseSelected) {
      expenseSelected.file = null;
      const newExpenses = expenses.filter(
        (expense) => expense._id !== expenseId
      );
      newExpenses.splice(expenseSelectedIndex, 0, expenseSelected);
      setExpenses(newExpenses);
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Eventos . {eventId ? "Editar evento" : "Adicionar evento"}
                </h3>
              </CardHeader>
              <Form onSubmit={(e) => handleFormSubmit(e)}>
                <CardBody>
                  {loadingEvent ? (
                    <div className="text-center d-flex flex-column justify-content-center align-items-center">
                      <FaSpinner className="spinner" />
                      <p className="m-0">Carregando evento</p>
                    </div>
                  ) : (
                    <>
                      {status === "created" && (
                        <Alert color="primary">
                          <i className="fas fa-exclamation-circle"></i> Você
                          pode alterar livrevemente o evento até que ele seja
                          enviado para análise.
                        </Alert>
                      )}
                      {status === "revision" && (
                        <Alert color="info">
                          <i className="fas fa-exclamation-circle"></i> Evento
                          sob revisão.
                        </Alert>
                      )}
                      {status === "correct" && (
                        <Alert color="warning">
                          <i className="fas fa-exclamation-circle"></i> Corrija
                          os campos destacados e envie novamente o evento para a
                          análise.
                        </Alert>
                      )}
                      {status === "finished" && (
                        <Alert color="success">
                          <i className="fas fa-exclamation-circle"></i> Evento
                          finalizado. Caso deseje, anexe fotos e informações
                          sobre o evento.
                        </Alert>
                      )}
                      {/* {`eventId: ${eventId}`}
                      <br />
                      {`status: ${status}`}
                      <br /> */}

                      {/* <Button
                        onClick={toggleHistoriesOpened}
                        className="m-0 mt-3 mb-3"
                      >
                        <FaRegClock />{" "}
                        {historiesOpened ? "Ocultar" : "Visualizar"} histórico
                        de alterações
                      </Button> */}

                      <h6 className="heading-small text-muted mb-4">
                        Informações gerais
                      </h6>
                      <Row>
                        <Col xl="6">
                          <Label className="form-control-label" htmlFor="name">
                            Nome do evento
                          </Label>
                          <FormGroup /* className="has-success" */>
                            <Input
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                              placeholder="Exemplo: Meu evento"
                              defaultValue={name}
                              onChange={(e) => handleName(e.target.value)}
                            />
                          </FormGroup>
                          {histories && histories.length ? (
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Nome do evento"],
                                body: ["name"],
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col xl="6">
                          <Label className="form-control-label" htmlFor="type">
                            Tipo do evento
                          </Label>
                          <FormGroup>
                            <Input
                              type="select"
                              name="event_type_id"
                              id="type"
                              className="form-control"
                              placeholder="Meu evento"
                              defaultValue={typeId}
                              onChange={(e) => handleTypeId(e.target.value)}
                            >
                              <option value="">Selecione o tipo</option>
                              {eventsTypes && eventsTypes.length
                                ? eventsTypes.map((eventType, index) => {
                                    return (
                                      <option key={index} value={eventType._id}>
                                        {eventType.name}
                                      </option>
                                    );
                                  })
                                : ""}
                            </Input>
                          </FormGroup>
                          {histories && histories.length ? (
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Tipo do evento"],
                                body: ["event_type_id.name"],
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="6">
                          <Label
                            className="form-control-label"
                            htmlFor="objective"
                          >
                            Objetivo
                          </Label>
                          <FormGroup>
                            <Input
                              type="textarea"
                              name="objective"
                              id="objective"
                              className="form-control"
                              placeholder="Todo o planejamento do evento está relacionado aos objetivos que se deseja alcançar com a sua realização.&#10;Assim, é necessário defini-los, com precisão, a fim de que os resultados obtidos sejam os previstos."
                              rows="8"
                              defaultValue={objective}
                              onChange={(e) => setObjective(e.target.value)}
                            />
                          </FormGroup>
                          {histories && histories.length ? (
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Objetivo"],
                                body: ["objective"],
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col xl="6">
                          <Label
                            className="form-control-label"
                            htmlFor="reason"
                          >
                            Justificativa
                          </Label>
                          <FormGroup>
                            <Input
                              type="textarea"
                              name="reason"
                              id="reason"
                              className="form-control"
                              placeholder="Relacionar o objetivo do evento com os objetivos institucionais.&#10;Principalmente quando o evento se enquadrar na Categoria Parceria, descrever os motivos pelos quais a solicitação deve ser aprovada.&#10;Quais são os ganhos e os resultados que serão alcançados com esta iniciativa?"
                              rows="8"
                              defaultValue={reason}
                              onChange={(e) => setReason(e.target.value)}
                            />
                          </FormGroup>
                          {histories && histories.length ? (
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Justificativa"],
                                body: ["reason"],
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="6">
                          <Label
                            className="form-control-label"
                            htmlFor="category_id"
                          >
                            Modalidade
                          </Label>
                          <FormGroup>
                            <Input
                              type="select"
                              name="category_id"
                              id="category_id"
                              className="form-control"
                              placeholder="Meu evento"
                              defaultValue={categoryId}
                              onChange={(e) =>
                                handleCategoryChange(e.target.value)
                              }
                            >
                              <option value="">Selecione a modalidade</option>
                              {eventsCategories && eventsCategories.length
                                ? eventsCategories.map(
                                    (eventCategory, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={eventCategory._id}
                                        >
                                          {eventCategory.name}
                                        </option>
                                      );
                                    }
                                  )
                                : ""}
                            </Input>
                          </FormGroup>
                          {histories && histories.length ? (
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Modalidade"],
                                body: ["category_id.name"],
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        {hasCategorySecondField && (
                          <Col xl="6">
                            <Label
                              className="form-control-label"
                              htmlFor="category_second_field_value"
                            >
                              {categorySecondFieldName}
                            </Label>
                            <FormGroup>
                              <Input
                                type="text"
                                name="category_second_field_value"
                                id="category_second_field_value"
                                className="form-control"
                                placeholder={categorySecondFieldName}
                                defaultValue={categorySecondFieldValue}
                                onChange={(e) =>
                                  handleCategorySecondFieldValue(e.target.value)
                                }
                              ></Input>
                            </FormGroup>
                            {histories && histories.length ? (
                              <HistoryItem
                                historiesOpened={historiesOpened}
                                histories={histories}
                                tableItems={{
                                  headers: ["Tipo da modalidade"],
                                  body: ["category_second_field_value"],
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col xl="6">
                          <Label
                            className="form-control-label"
                            htmlFor="coverage_id"
                          >
                            Abrangência (quem poderá participar)
                          </Label>
                          <FormGroup>
                            <Input
                              type="select"
                              name="coverage_id"
                              id="coverage_id"
                              className="form-control"
                              placeholder="Meu evento"
                              defaultValue={coverageId}
                              onChange={(e) =>
                                handleCoverageChange(e.target.value)
                              }
                            >
                              <option value="">Selecione a abrangência</option>
                              {eventsCoverages && eventsCoverages.length
                                ? eventsCoverages.map(
                                    (eventCoverage, index) => {
                                      return (
                                        <option
                                          key={eventCoverage._id}
                                          value={eventCoverage._id}
                                        >
                                          {eventCoverage.name}
                                        </option>
                                      );
                                    }
                                  )
                                : ""}
                            </Input>
                          </FormGroup>
                          {histories && histories.length ? (
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Abrangência"],
                                body: ["coverage_id.name"],
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        {hasCoverageSecondField && (
                          <Col xl="6">
                            <Label
                              className="form-control-label"
                              htmlFor="coverage_second_field_value"
                            >
                              {coverageSecondFieldName}
                            </Label>
                            <FormGroup>
                              <Input
                                type="text"
                                name="coverage_second_field_value"
                                id="coverage_second_field_value"
                                className="form-control"
                                placeholder={coverageSecondFieldName}
                                defaultValue={coverageSecondFieldValue}
                                onChange={(e) =>
                                  handleCoverageSecondFieldValue(e.target.value)
                                }
                              ></Input>
                            </FormGroup>
                            {histories && histories.length ? (
                              <HistoryItem
                                historiesOpened={historiesOpened}
                                histories={histories}
                                tableItems={{
                                  headers: ["Tipo da abrangência"],
                                  body: ["coverage_second_field_value"],
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        )}
                      </Row>
                      <Row className="mt-3 mb-5">
                        <Col>
                          {dates && dates.length ? (
                            dates.map((date, index) => {
                              return (
                                <div className="date-item" key={date._id}>
                                  <Row>
                                    <Col xl="12">
                                      <h2 className="mb-3">Dia {index + 1}</h2>
                                    </Col>
                                  </Row>
                                  <Row
                                    // key={date._id}
                                    className={
                                      index === 0 ? "align-items-center" : ""
                                    }
                                  >
                                    <Col xl="4" sm="6">
                                      <FormGroup>
                                        {/* TODO: Quando for mobile, exibir as labels também */}
                                        {index === 0 && (
                                          <Label className="form-control-label">
                                            Dia
                                          </Label>
                                        )}
                                        <Input
                                          type="date"
                                          defaultValue={date.date}
                                          onChange={(e) =>
                                            handleDateData(
                                              "date",
                                              date._id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xl="3" sm="6">
                                      <FormGroup>
                                        {index === 0 && (
                                          <Label className="form-control-label">
                                            Horário início
                                          </Label>
                                        )}
                                        <Input
                                          type="time"
                                          defaultValue={date.start_time}
                                          onChange={(e) =>
                                            handleDateData(
                                              "start_time",
                                              date._id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xl="3" sm="6">
                                      <FormGroup>
                                        {index === 0 && (
                                          <Label className="form-control-label">
                                            Horário fim
                                          </Label>
                                        )}
                                        <Input
                                          type="time"
                                          defaultValue={date.end_time}
                                          onChange={(e) =>
                                            handleDateData(
                                              "end_time",
                                              date._id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col xl="2" sm="6" className="text-right">
                                      <Button
                                        color="danger"
                                        onClick={() =>
                                          handleDeleteDate(date._id)
                                        }
                                      >
                                        <FaTrash />
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="3">
                                      <Label
                                        className="form-control-label"
                                        htmlFor="workload"
                                      >
                                        Carga horária
                                      </Label>
                                      <FormGroup>
                                        <InputGroup>
                                          <Input
                                            type="number"
                                            name="workload"
                                            id="workload"
                                            className="form-control"
                                            min="0"
                                            step="0.1"
                                            aria-label="Carga horária"
                                            aria-describedby={`carga-horaria-${date._id}`}
                                            placeholder="Exemplo: 2.5"
                                            defaultValue={date.workload}
                                            onChange={(e) =>
                                              handleDateData(
                                                "workload",
                                                date._id,
                                                e.target.value
                                              )
                                            }
                                          />
                                          <div className="input-group-append">
                                            <span
                                              className="input-group-text"
                                              id={`carga-horaria-${date._id}`}
                                            >
                                              h
                                            </span>
                                          </div>
                                        </InputGroup>
                                      </FormGroup>
                                      {histories && histories.length ? (
                                        <HistoryItem
                                          historiesOpened={historiesOpened}
                                          histories={histories}
                                          tableItems={{
                                            headers: ["Carga horária"],
                                            body: ["workload"],
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                    <Col xl="6">
                                      <Label
                                        className="form-control-label"
                                        htmlFor="place"
                                      >
                                        Local
                                      </Label>
                                      <FormGroup>
                                        <Input
                                          type="text"
                                          name="place"
                                          id="place"
                                          className="form-control"
                                          placeholder="Exemplo: Faculdade"
                                          defaultValue={date.place}
                                          onChange={(e) =>
                                            handleDateData(
                                              "place",
                                              date._id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FormGroup>
                                      {histories && histories.length ? (
                                        <HistoryItem
                                          historiesOpened={historiesOpened}
                                          histories={histories}
                                          tableItems={{
                                            headers: ["Local"],
                                            body: ["place"],
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                    <Col xl="3">
                                      <Label
                                        className="form-control-label"
                                        htmlFor="audience_estimate"
                                      >
                                        Estimativa de público
                                      </Label>
                                      <FormGroup>
                                        <InputGroup>
                                          <Input
                                            type="number"
                                            name="audience_estimate"
                                            id="audience_estimate"
                                            className="form-control"
                                            aria-label="Estimativa de público"
                                            aria-describedby={`audience_estimate${date._id}`}
                                            min="0"
                                            placeholder="Exemplo: 50"
                                            defaultValue={
                                              date.audience_estimate
                                            }
                                            onChange={(e) =>
                                              // setAudienceEstimate();
                                              handleDateData(
                                                "audience_estimate",
                                                date._id,
                                                e.target.value
                                              )
                                            }
                                          />
                                          <div className="input-group-append">
                                            <span className="input-group-text">
                                              pessoas
                                            </span>
                                          </div>
                                        </InputGroup>
                                      </FormGroup>
                                      {histories && histories.length ? (
                                        <HistoryItem
                                          historiesOpened={historiesOpened}
                                          histories={histories}
                                          tableItems={{
                                            headers: ["Estimativa de público"],
                                            body: ["audience_estimate"],
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="3">
                                      <Label
                                        className="form-control-label"
                                        htmlFor="online"
                                      >
                                        Evento online?
                                      </Label>
                                      <FormGroup
                                        check
                                        style={{
                                          paddingBottom:
                                            "2.9em" /* TODO: Remover no mobile */,
                                        }}
                                      >
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            name="online"
                                            id="online"
                                            defaultChecked={date.online}
                                            onChange={(e) =>
                                              handleDateData(
                                                "online",
                                                date._id,
                                                e.target.checked
                                              )
                                            }
                                          />
                                          Sim
                                        </Label>
                                      </FormGroup>
                                      {histories && histories.length ? (
                                        <HistoryItem
                                          historiesOpened={historiesOpened}
                                          histories={histories}
                                          tableItems={{
                                            headers: ["Evento online?"],
                                            body: ["online"],
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                    {date.online && (
                                      <Col xl="9">
                                        <Label
                                          className="form-control-label"
                                          htmlFor="link"
                                        >
                                          Link (caso seja online)
                                        </Label>
                                        <FormGroup>
                                          <Input
                                            type="text"
                                            name="link"
                                            id="link"
                                            className="form-control"
                                            placeholder="Exemplo: https://meet.google.com/..."
                                            defaultValue={date.link}
                                            onChange={(e) =>
                                              // setLink(e.target.value)
                                              handleDateData(
                                                "link",
                                                date._id,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </FormGroup>
                                        {histories && histories.length ? (
                                          <HistoryItem
                                            historiesOpened={historiesOpened}
                                            histories={histories}
                                            tableItems={{
                                              headers: ["Link"],
                                              body: ["link"],
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    )}
                                  </Row>
                                  <Row>
                                    <Col xl="6">
                                      <Label
                                        className="form-control-label"
                                        htmlFor="ticket"
                                      >
                                        Ingresso
                                      </Label>
                                      <FormGroup>
                                        <Input
                                          type="textarea"
                                          name="ticket"
                                          id="ticket"
                                          className="form-control"
                                          placeholder="Informe se haverá venda de ingressos, se é isento ou arrecadação de donativos.&#10;Especifique se os participantes terão algum investimento para participar da atividade."
                                          rows="8"
                                          defaultValue={date.ticket}
                                          onChange={(e) =>
                                            // setTicket(e.target.value)
                                            handleDateData(
                                              "ticket",
                                              date._id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FormGroup>
                                      {histories && histories.length ? (
                                        <HistoryItem
                                          historiesOpened={historiesOpened}
                                          histories={histories}
                                          tableItems={{
                                            headers: ["Ingresso"],
                                            body: ["ticket"],
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                    <Col xl="6">
                                      <Label
                                        className="form-control-label"
                                        htmlFor="schedule"
                                      >
                                        Programação/roteiro
                                      </Label>
                                      <FormGroup>
                                        <Input
                                          type="textarea"
                                          name="schedule"
                                          id="schedule"
                                          className="form-control"
                                          placeholder="Informe o roteiro do evento/atividade, com os horários previstos de execução e breve descrição de cada momento.&#10;Pode/deve ser inserido o conteúdo programático."
                                          rows="8"
                                          defaultValue={date.schedule}
                                          onChange={(e) =>
                                            // setSchedule(e.target.value)
                                            handleDateData(
                                              "schedule",
                                              date._id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FormGroup>
                                      {histories && histories.length ? (
                                        <HistoryItem
                                          historiesOpened={historiesOpened}
                                          histories={histories}
                                          tableItems={{
                                            headers: ["Programação/roteiro"],
                                            body: ["schedule"],
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="6">
                                      <Label
                                        className="form-control-label"
                                        htmlFor="details"
                                      >
                                        Detalhamento
                                      </Label>
                                      <FormGroup>
                                        <Input
                                          type="textarea"
                                          name="details"
                                          id="details"
                                          className="form-control"
                                          placeholder="- Apresentar os subsídios necessários para o entendimento da proposta. Descrever como será a organização do evento.&#10;- Realizar considerações importantes que possam ser utilizadas na elaboração das estratégias para divulgação e captação de público, entre outros. &#10;- Apresentar variáveis. Exemplo: se a atividade for ao ar livre, em caso de chuva, será cancelado, transferido ou adequado."
                                          rows="8"
                                          defaultValue={date.details}
                                          onChange={(e) =>
                                            // setDetails(e.target.value)
                                            handleDateData(
                                              "details",
                                              date._id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FormGroup>
                                      {histories && histories.length ? (
                                        <HistoryItem
                                          historiesOpened={historiesOpened}
                                          histories={histories}
                                          tableItems={{
                                            headers: ["Detalhamento"],
                                            body: ["details"],
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                    <Col xl="6">
                                      <Label
                                        className="form-control-label"
                                        htmlFor="resources"
                                      >
                                        Recursos e infraestrutura
                                      </Label>
                                      <FormGroup>
                                        <Input
                                          type="textarea"
                                          name="resources"
                                          id="resources"
                                          className="form-control"
                                          placeholder="Informe quais os recursos materiais, do ambiente e de pessoas são necessários."
                                          rows="8"
                                          defaultValue={date.resources}
                                          onChange={(e) =>
                                            // setResources(e.target.value)
                                            handleDateData(
                                              "resources",
                                              date._id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </FormGroup>
                                      {histories && histories.length ? (
                                        <HistoryItem
                                          historiesOpened={historiesOpened}
                                          histories={histories}
                                          tableItems={{
                                            headers: [
                                              "Recursos e infraestrutura",
                                            ],
                                            body: ["resources"],
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })
                          ) : (
                            <p className="form-control-label">
                              Nenhuma data informada. Adicione ao menos uma data
                              para solicitar o evento
                            </p>
                          )}
                          <Row className="mt-4">
                            <Col className="text-right">
                              <Button
                                color="primary"
                                type="button"
                                onClick={() => handleNewDate()}
                              >
                                <FaPlus /> Adicionar data
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {histories && histories.length ? (
                        <Row>
                          <Col xl="12">
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Dia | Horário início | Horário fim"],
                                body: ["|ARRAY|dates.infos"],
                              }}
                            />
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      {/* <Row>
                        <Col xl="3">
                          <Label
                            className="form-control-label"
                            htmlFor="workload"
                          >
                            Carga horária
                          </Label>
                          <FormGroup>
                            <InputGroup>
                              <Input
                                type="number"
                                name="workload"
                                id="workload"
                                className="form-control"
                                min="0"
                                step="0.1"
                                aria-label="Carga horária"
                                aria-describedby="carga-horaria-01"
                                placeholder="Exemplo: 2.5"
                                defaultValue={workload}
                                onChange={(e) => setWorkload(e.target.value)}
                              />
                              <div className="input-group-append">
                                <span
                                  className="input-group-text"
                                  id="carga-horaria-01"
                                >
                                  h
                                </span>
                              </div>
                            </InputGroup>
                          </FormGroup>
                          {histories && histories.length ? (
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Carga horária"],
                                body: ["workload"],
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col xl="6">
                          <Label className="form-control-label" htmlFor="place">
                            Local
                          </Label>
                          <FormGroup>
                            <Input
                              type="text"
                              name="place"
                              id="place"
                              className="form-control"
                              placeholder="Exemplo: Faculdade"
                              defaultValue={place}
                              onChange={(e) => setPlace(e.target.value)}
                            />
                          </FormGroup>
                          {histories && histories.length ? (
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Local"],
                                body: ["place"],
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col xl="3">
                          <Label
                            className="form-control-label"
                            htmlFor="audience_estimate"
                          >
                            Estimativa de público
                          </Label>
                          <FormGroup>
                            <InputGroup>
                              <Input
                                type="number"
                                name="audience_estimate"
                                id="audience_estimate"
                                className="form-control"
                                aria-label="Estimativa de público"
                                aria-describedby="audience_estimate-01"
                                min="0"
                                placeholder="Exemplo: 50"
                                defaultValue={audienceEstimate}
                                onChange={(e) =>
                                  setAudienceEstimate(e.target.value)
                                }
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  pessoas
                                </span>
                              </div>
                            </InputGroup>
                          </FormGroup>
                          {histories && histories.length ? (
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Estimativa de público"],
                                body: ["audience_estimate"],
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="3">
                          <Label
                            className="form-control-label"
                            htmlFor="online"
                          >
                            Evento online?
                          </Label>
                          <FormGroup
                            check
                            style={{
                              paddingBottom:
                                "2.9em" // TODO: Remover no mobile,
                            }}
                          >
                            <Label check>
                              <Input
                                type="checkbox"
                                name="online"
                                id="online"
                                onChange={() => setOnlineEvent(!onlineEvent)}
                                defaultChecked={onlineEvent}
                              />
                              Sim
                            </Label>
                          </FormGroup>
                          {histories && histories.length ? (
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Evento online?"],
                                body: ["online"],
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        {onlineEvent && (
                          <Col xl="9">
                            <Label
                              className="form-control-label"
                              htmlFor="link"
                            >
                              Link (caso seja online)
                            </Label>
                            <FormGroup>
                              <Input
                                type="text"
                                name="link"
                                id="link"
                                className="form-control"
                                placeholder="Exemplo: https://meet.google.com/..."
                                defaultValue={link}
                                onChange={(e) => setLink(e.target.value)}
                              />
                            </FormGroup>
                            {histories && histories.length ? (
                              <HistoryItem
                                historiesOpened={historiesOpened}
                                histories={histories}
                                tableItems={{
                                  headers: ["Link"],
                                  body: ["link"],
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col xl="6">
                          <Label
                            className="form-control-label"
                            htmlFor="ticket"
                          >
                            Ingresso
                          </Label>
                          <FormGroup>
                            <Input
                              type="textarea"
                              name="ticket"
                              id="ticket"
                              className="form-control"
                              placeholder="Informe se haverá venda de ingressos, se é isento ou arrecadação de donativos.&#10;Especifique se os participantes terão algum investimento para participar da atividade."
                              rows="8"
                              defaultValue={ticket}
                              onChange={(e) => setTicket(e.target.value)}
                            />
                          </FormGroup>
                          {histories && histories.length ? (
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Ingresso"],
                                body: ["ticket"],
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col xl="6">
                          <Label
                            className="form-control-label"
                            htmlFor="schedule"
                          >
                            Programação/roteiro
                          </Label>
                          <FormGroup>
                            <Input
                              type="textarea"
                              name="schedule"
                              id="schedule"
                              className="form-control"
                              placeholder="Informe o roteiro do evento/atividade, com os horários previstos de execução e breve descrição de cada momento.&#10;Pode/deve ser inserido o conteúdo programático."
                              rows="8"
                              defaultValue={schedule}
                              onChange={(e) => setSchedule(e.target.value)}
                            />
                          </FormGroup>
                          {histories && histories.length ? (
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Programação/roteiro"],
                                body: ["schedule"],
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="6">
                          <Label
                            className="form-control-label"
                            htmlFor="details"
                          >
                            Detalhamento
                          </Label>
                          <FormGroup>
                            <Input
                              type="textarea"
                              name="details"
                              id="details"
                              className="form-control"
                              placeholder="- Apresentar os subsídios necessários para o entendimento da proposta. Descrever como será a organização do evento.&#10;- Realizar considerações importantes que possam ser utilizadas na elaboração das estratégias para divulgação e captação de público, entre outros. &#10;- Apresentar variáveis. Exemplo: se a atividade for ao ar livre, em caso de chuva, será cancelado, transferido ou adequado."
                              rows="8"
                              defaultValue={details}
                              onChange={(e) => setDetails(e.target.value)}
                            />
                          </FormGroup>
                          {histories && histories.length ? (
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Detalhamento"],
                                body: ["details"],
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col xl="6">
                          <Label
                            className="form-control-label"
                            htmlFor="resources"
                          >
                            Recursos e infraestrutura
                          </Label>
                          <FormGroup>
                            <Input
                              type="textarea"
                              name="resources"
                              id="resources"
                              className="form-control"
                              placeholder="Informe quais os recursos materiais, do ambiente e de pessoas são necessários."
                              rows="8"
                              defaultValue={resources}
                              onChange={(e) => setResources(e.target.value)}
                            />
                          </FormGroup>
                          {histories && histories.length ? (
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: ["Recursos e infraestrutura"],
                                body: ["resources"],
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row> */}
                      <hr className="mt-1 mb-3" />
                      <h6 className="heading-small text-muted mb-4">
                        Palestrantes e Convidados
                      </h6>
                      <Row className="mt-3 mb-5">
                        <Col>
                          {lecturers && lecturers.length ? (
                            lecturers.map((lecturer) => {
                              return (
                                <Row
                                  className="lecturer-item"
                                  key={lecturer._id}
                                >
                                  <Col xl="6">
                                    <FormGroup>
                                      <Label className="form-control-label">
                                        Nome completo
                                      </Label>
                                      <Input
                                        type="text"
                                        placeholder="Exemplo: João"
                                        value={lecturer.name ?? ""}
                                        onChange={(e) =>
                                          handleLecturerName(
                                            lecturer._id,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormGroup>
                                    {lecturersFilteredIndex === lecturer._id &&
                                      filteredLecturers &&
                                      filteredLecturers.length > 0 && (
                                        <div>
                                          <Alert color="info" className="p-3">
                                            Caso deseje, você pode selecionar um
                                            palestrante já cadastrado.
                                          </Alert>
                                          <p className="m-0">
                                            Palestrantes já cadastrados:
                                          </p>
                                          <ul>
                                            {filteredLecturers.map(
                                              (filteredLecturer, i) => (
                                                <li
                                                  key={i}
                                                  onClick={() =>
                                                    handleFilteredLecturerSelected(
                                                      lecturer._id,
                                                      filteredLecturer
                                                    )
                                                  }
                                                >
                                                  {`${filteredLecturer.name} (${filteredLecturer.office})`}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                  </Col>
                                  <Col xl="6">
                                    <FormGroup>
                                      <Label className="form-control-label">
                                        Titulação/Cargo
                                      </Label>
                                      <Input
                                        type="text"
                                        placeholder="Exemplo: Gerente Administrativo na EMPRESA X"
                                        defaultValue={lecturer.office}
                                        onChange={(e) =>
                                          handleLecturer(
                                            lecturer._id,
                                            "office",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col xl="6">
                                    <FormGroup>
                                      <Label className="form-control-label">
                                        Lattes
                                      </Label>
                                      <Input
                                        type="text"
                                        placeholder="Exemplo: https://lattes.cnpq.br/..."
                                        defaultValue={lecturer.lattes}
                                        onChange={(e) =>
                                          handleLecturer(
                                            lecturer._id,
                                            "lattes",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col xl="6">
                                    <FormGroup>
                                      <Label className="form-control-label">
                                        E-mail
                                      </Label>
                                      <Input
                                        type="email"
                                        placeholder="Exemplo: contato@email.com"
                                        defaultValue={lecturer.email}
                                        onChange={(e) =>
                                          handleLecturer(
                                            lecturer._id,
                                            "email",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col lg="3">
                                    <FormGroup>
                                      <Label
                                        className="form-control-label"
                                        htmlFor={`guest_${lecturer._id}`}
                                      >
                                        É convidado?
                                      </Label>
                                      <FormGroup check>
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            name="guest"
                                            id={`guest_${lecturer._id}`}
                                            defaultChecked={lecturer.guest}
                                            onChange={(e) =>
                                              handleLecturer(
                                                lecturer._id,
                                                "guest",
                                                e.target
                                              )
                                            }
                                          />
                                          Sim
                                        </Label>
                                      </FormGroup>
                                    </FormGroup>
                                  </Col>

                                  {dates && dates.length ? (
                                    <Col
                                      xl="12"
                                      className="order-md-2 order-sm-1 mb-3"
                                    >
                                      <h4>
                                        Datas em que o palestrante/convidado irá
                                        participar
                                      </h4>
                                      {dates.map((date, index) => {
                                        return (
                                          <FormGroup check key={date._id}>
                                            <Label check>
                                              <Input
                                                type="checkbox"
                                                name="guest"
                                                id={`lecturer_dates_${lecturer._id}`}
                                                defaultChecked={
                                                  lecturer.dates?.[index]
                                                }
                                                onChange={(e) =>
                                                  handleLecturer(
                                                    lecturer._id,
                                                    "dates",
                                                    e.target,
                                                    index
                                                  )
                                                }
                                              />
                                              {date?.date
                                                ? `Dia ${
                                                    index + 1
                                                  }: (${formatDate(date.date)}${
                                                    date?.start_time
                                                      ? `, às ${
                                                          date.start_time
                                                        } ${
                                                          date?.end_time
                                                            ? ` até ${date.end_time}`
                                                            : ""
                                                        }`
                                                      : ""
                                                  })`
                                                : `Dia ${index + 1}`}
                                            </Label>
                                          </FormGroup>
                                        );
                                      })}
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  <Col
                                    xl="9"
                                    md="12"
                                    className="align-self-center text-right order-xl-1 order-sm-2"
                                  >
                                    <Button
                                      color="danger"
                                      onClick={() =>
                                        handleDeleteLecturer(lecturer._id)
                                      }
                                    >
                                      <FaTrash />
                                    </Button>
                                  </Col>
                                </Row>
                              );
                            })
                          ) : (
                            <p className="form-control-label">
                              Nenhum palestrante ou convidado informado.
                              Adicione ao menos um palestrante ou convidado para
                              solicitar o evento.
                            </p>
                          )}
                          <Row className="mt-4">
                            <Col className="text-right">
                              <Button
                                color="primary"
                                type="button"
                                onClick={() => handleNewLecturer()}
                              >
                                <FaPlus /> Adicionar palestrante/convidado
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {histories && histories.length ? (
                        <Row>
                          <Col xl="12">
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: [
                                  "Nome | Titulação/cargo | Lattes | E-mail | Convidado",
                                ],
                                body: ["|ARRAY|lecturers.infos"],
                              }}
                            />
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      <hr className="mt-1 mb-3" />
                      <h6 className="heading-small text-muted mb-4">
                        Organizadores e Colaboradores
                      </h6>
                      <Row className="mt-3 mb-5">
                        <Col>
                          {organizers && organizers.length ? (
                            organizers.map((organizer) => {
                              return (
                                <Row
                                  className="organizer-item"
                                  key={organizer._id}
                                >
                                  <Col xl="6">
                                    <FormGroup>
                                      <Label className="form-control-label">
                                        Nome completo
                                      </Label>
                                      <Input
                                        type="text"
                                        placeholder="Exemplo: João"
                                        value={organizer.name ?? ""}
                                        onChange={(e) =>
                                          handleOrganizerName(
                                            organizer._id,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormGroup>
                                    {organizersFilteredIndex ===
                                      organizer._id &&
                                      filteredOrganizers &&
                                      filteredOrganizers.length > 0 && (
                                        <div>
                                          <Alert color="info" className="p-3">
                                            Caso deseje, você pode selecionar um
                                            organizador já cadastrado.
                                          </Alert>
                                          <p className="m-0">
                                            Organizadores já cadastrados:
                                          </p>
                                          <ul>
                                            {filteredOrganizers.map(
                                              (filteredOrganizer, i) => (
                                                <li
                                                  key={i}
                                                  onClick={() =>
                                                    handleFilteredOrganizerSelected(
                                                      organizer._id,
                                                      filteredOrganizer
                                                    )
                                                  }
                                                >
                                                  {`${filteredOrganizer.name} (${filteredOrganizer.identification})`}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                  </Col>
                                  <Col xl="6">
                                    <FormGroup>
                                      <Label className="form-control-label">
                                        Identificação
                                      </Label>
                                      <Input
                                        type="select"
                                        className="form-control"
                                        defaultValue={organizer.identification}
                                        onChange={(e) =>
                                          handleOrganizer(
                                            organizer._id,
                                            "identification",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">
                                          Selecione uma identificação
                                        </option>
                                        {organizersIdentifications &&
                                        organizersIdentifications.length
                                          ? organizersIdentifications.map(
                                              (
                                                organizerIdentification,
                                                index
                                              ) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    value={
                                                      organizerIdentification
                                                    }
                                                  >
                                                    {organizerIdentification}
                                                  </option>
                                                );
                                              }
                                            )
                                          : ""}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col xl="6">
                                    <FormGroup>
                                      <Label className="form-control-label">
                                        Função
                                      </Label>
                                      <Input
                                        type="select"
                                        className="form-control"
                                        defaultValue={organizer.office}
                                        onChange={(e) =>
                                          handleOrganizer(
                                            organizer._id,
                                            "office",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">
                                          Selecione uma função
                                        </option>
                                        {organizersOffices &&
                                        organizersOffices.length
                                          ? organizersOffices.map(
                                              (organizerOffice, index) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    value={organizerOffice}
                                                  >
                                                    {organizerOffice}
                                                  </option>
                                                );
                                              }
                                            )
                                          : ""}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col xl="6">
                                    <FormGroup>
                                      <Label className="form-control-label">
                                        E-mail
                                      </Label>
                                      <Input
                                        type="email"
                                        placeholder="Exemplo: contato@email.com"
                                        defaultValue={organizer.email}
                                        onChange={(e) =>
                                          handleOrganizer(
                                            organizer._id,
                                            "email",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col xl="3">
                                    <FormGroup>
                                      <Label className="form-control-label">
                                        Carga horária
                                      </Label>
                                      <InputGroup>
                                        <Input
                                          type="number"
                                          min="0"
                                          step="0.1"
                                          aria-label="Carga horária"
                                          aria-describedby={`carga-horaria-org-${organizer._id}`}
                                          placeholder="Exemplo: 2.5"
                                          defaultValue={organizer.workload}
                                          onChange={(e) =>
                                            handleOrganizer(
                                              organizer._id,
                                              "workload",
                                              e.target.value
                                            )
                                          }
                                        />
                                        <div className="input-group-append">
                                          <span
                                            className="input-group-text"
                                            id={`carga-horaria-org-${organizer._id}`}
                                          >
                                            h
                                          </span>
                                        </div>
                                      </InputGroup>
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    xl="9"
                                    sm="12"
                                    className="align-self-center text-right"
                                  >
                                    <Button
                                      color="danger"
                                      onClick={() =>
                                        handleDeleteOrganizer(organizer._id)
                                      }
                                    >
                                      <FaTrash />
                                    </Button>
                                  </Col>
                                </Row>
                              );
                            })
                          ) : (
                            <p className="form-control-label">
                              Nenhum organizador ou colaborador informado.
                              Adicione ao menos um organizador ou colaborador
                              para solicitar o evento.
                            </p>
                          )}
                          <Row className="mt-4">
                            <Col className="text-right">
                              <Button
                                color="primary"
                                type="button"
                                onClick={() => handleNewOrganizer()}
                              >
                                <FaPlus /> Adicionar organizador/colaborador
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {histories && histories.length ? (
                        <Row>
                          <Col xl="12">
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: [
                                  "Nome | Identificação | Função | E-mail | Carga horária",
                                ],
                                body: ["|ARRAY|organizers.infos"],
                              }}
                            />
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      <hr className="mt-1 mb-3" />
                      <h6 className="heading-small text-muted mb-4">
                        Despesas
                      </h6>
                      <Row className="mt-3 mb-5">
                        <Col>
                          {expenses && expenses.length ? (
                            expenses.map((expense) => {
                              return (
                                <Row key={expense._id}>
                                  <Col xl="6">
                                    <Label className="form-control-label">
                                      Tipo de despesa
                                    </Label>
                                    <FormGroup>
                                      <Input
                                        type="select"
                                        name="event_expense_type_id"
                                        id="type"
                                        className="form-control"
                                        defaultValue={
                                          expense.event_expense_type_id?._id
                                        }
                                        onChange={(e) =>
                                          handleExpense(
                                            expense._id,
                                            "event_expense_type_id",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">
                                          Selecione o tipo de despesa
                                        </option>
                                        {expensesTypes && expensesTypes.length
                                          ? expensesTypes.map(
                                              (expenseType, index) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    value={expenseType._id}
                                                  >
                                                    {expenseType.name}
                                                  </option>
                                                );
                                              }
                                            )
                                          : ""}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col xl="6">
                                    <FormGroup>
                                      <Label className="form-control-label">
                                        Fornecedor
                                      </Label>
                                      <Input
                                        type="text"
                                        placeholder="Exemplo: Transporte X"
                                        defaultValue={expense.provider}
                                        onChange={(e) =>
                                          handleExpense(
                                            expense._id,
                                            "provider",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col xl="6">
                                    <FormGroup>
                                      <Label className="form-control-label">
                                        Valor
                                      </Label>
                                      <CurrencyInput
                                        id={`expense_amount_${expense._id}`}
                                        name={`expense_amount_${expense._id}`}
                                        className="form-control"
                                        placeholder="Exemplo: R$200,00"
                                        defaultValue={expense.amount}
                                        prefix="R$"
                                        decimalsLimit={2}
                                        allowNegativeValue={false}
                                        maxLength={12}
                                        decimalSeparator=","
                                        groupSeparator="."
                                        onChange={(e) =>
                                          handleExpense(
                                            expense._id,
                                            "amount",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col xl="6">
                                    {/* TODO: Edit view -> show file with shortcut link */}
                                    <FormGroup className="m-0">
                                      <Label className="form-control-label">
                                        Anexar orçamento
                                      </Label>
                                      {!expense.file && (
                                        <Upload
                                          onUpload={(e) =>
                                            handleUpload(expense._id, e)
                                          }
                                        />
                                      )}
                                      {expense.file && (
                                        <FileItem
                                          fileUploaded={expense.file_uploaded}
                                          file={expense.file}
                                          onDelete={() =>
                                            handleDeleteFile(expense._id)
                                          }
                                        />
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col xl="12">
                                    <Label className="form-control-label">
                                      Comentários
                                    </Label>
                                    <FormGroup>
                                      <Input
                                        type="textarea"
                                        placeholder="Aponte e detalhe os recursos e infraestrutura que serão utilizados."
                                        rows="8"
                                        defaultValue={expense.comments}
                                        onChange={(e) =>
                                          handleExpense(
                                            expense._id,
                                            "comments",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    xl="12"
                                    className="align-self-center text-right"
                                  >
                                    <Button
                                      color="danger"
                                      onClick={() =>
                                        handleDeleteExpense(expense._id)
                                      }
                                    >
                                      <FaTrash />
                                    </Button>
                                  </Col>
                                </Row>
                              );
                            })
                          ) : (
                            <></>
                            // <p className="form-control-label">
                            //   Nenhuma despesa informada. Adicione ao menos uma
                            //   despesa para solicitar o evento.
                            // </p>
                          )}
                          <Row className="mt-4">
                            <Col className="text-right">
                              <Button
                                color="primary"
                                type="button"
                                onClick={() => handleNewExpense()}
                              >
                                <FaPlus /> Adicionar despesa
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {histories && histories.length ? (
                        <Row>
                          <Col xl="12">
                            <HistoryItem
                              historiesOpened={historiesOpened}
                              histories={histories}
                              tableItems={{
                                headers: [
                                  "Tipo | Fornecedor | Valor",
                                  "Arquivo",
                                  "Comentários",
                                ],
                                body: [
                                  "|ARRAY|expenses.infos",
                                  "|ARRAY|expenses.file",
                                  "|ARRAY|expenses.comments",
                                ],
                              }}
                            />
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      <hr className="mt-1 mb-3" />
                      <h6 className="heading-small text-muted mb-4">
                        Receita e investimento
                      </h6>
                      <Row>
                        <Col md="6">
                          <Label
                            className="form-control-label"
                            htmlFor="receipt_amount"
                          >
                            Previsão de receita
                          </Label>
                          <FormGroup>
                            <CurrencyInput
                              id="receipt_amount"
                              name="receipt_amount"
                              className="form-control"
                              placeholder="Ex.: R$2000,00"
                              prefix="R$"
                              decimalsLimit={2}
                              allowNegativeValue={false}
                              maxLength={12}
                              decimalSeparator=","
                              groupSeparator="."
                              defaultValue={receiptAmount}
                              onValueChange={(value) =>
                                setReceiptAmount(
                                  formatToDecimalAndParseAmount(value)
                                )
                              }
                            />
                          </FormGroup>
                          {histories && histories.length ? (
                            <Row>
                              <Col xl="12">
                                <HistoryItem
                                  historiesOpened={historiesOpened}
                                  histories={histories}
                                  tableItems={{
                                    headers: ["Previsão de receita"],
                                    body: ["receipt_amount"],
                                  }}
                                />
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col md="6">
                          <Label
                            className="form-control-label"
                            htmlFor="total_amount"
                          >
                            Total de despesas
                          </Label>
                          <FormGroup>
                            <CurrencyInput
                              name="total_amount"
                              id="total_amount"
                              className="form-control"
                              placeholder="Ex.: R$3200,00"
                              prefix="R$"
                              decimalsLimit={2}
                              allowNegativeValue={false}
                              maxLength={12}
                              decimalSeparator=","
                              groupSeparator="."
                              defaultValue={totalAmount}
                              onValueChange={(value) =>
                                setTotalAmount(
                                  formatToDecimalAndParseAmount(value)
                                )
                              }
                            />
                          </FormGroup>
                          {histories && histories.length ? (
                            <Row>
                              <Col xl="12">
                                <HistoryItem
                                  historiesOpened={historiesOpened}
                                  histories={histories}
                                  tableItems={{
                                    headers: ["Total"],
                                    body: ["total_amount"],
                                  }}
                                />
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
                <CardFooter className="border-0">
                  <Link to="/admin/events/">
                    <Button
                      type="button"
                      color="danger"
                      outline
                      className="mb-2 mr-2"
                    >
                      Cancelar
                    </Button>
                  </Link>
                  {["created", "correct"].indexOf(status) !== -1 && (
                    <Button
                      type="button"
                      color="success"
                      outline
                      disabled={formDisabled}
                      className="ml-0 mb-2"
                      onClick={(e) => handleButtonSubmitted(e, "revision")}
                    >
                      Salvar e enviar
                    </Button>
                  )}
                  <Button
                    type="submit"
                    color="success"
                    disabled={formDisabled}
                    className="ml-0 mb-2"
                  >
                    Salvar
                  </Button>
                  {status === "revision" && (
                    <Button
                      type="button"
                      color="success"
                      outline
                      disabled={formDisabled}
                      className="ml-0 mb-2"
                      onClick={(e) => handleButtonSubmitted(e, "correct")}
                    >
                      Salvar e retornar ao proponente
                    </Button>
                  )}
                  {status === "revision" && (
                    <Button
                      type="button"
                      color="info"
                      outline
                      disabled={formDisabled}
                      className="ml-0 mb-2"
                      onClick={(e) => handleButtonSubmitted(e, "approved")}
                    >
                      Salvar e aprovar
                    </Button>
                  )}
                  {status === "revision" && (
                    <Button
                      type="button"
                      color="warning"
                      outline
                      disabled={formDisabled}
                      className="ml-0 mb-2"
                      onClick={(e) => handleButtonSubmitted(e, "refused")}
                    >
                      Salvar e recusar
                    </Button>
                  )}
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const HistoryItem = ({
  historiesOpened = false,
  histories = [],
  tableItems = [],
}) => {
  return null;
  if (!tableItems.headers) {
    tableItems.headers = [];
  }
  ["Alterado por", "Data da alteração"].map((headerItem) => {
    tableItems.headers.push(headerItem);
  });
  if (!tableItems.body) {
    tableItems.body = [];
  }
  ["user_id.name", "updatedAt"].map((bodyItem) => {
    tableItems.body.push(bodyItem);
  });

  return (
    <Collapse isOpen={historiesOpened}>
      {/* <Card>
        <CardBody> */}
      <Table
        responsive
        striped
        hover
        size="sm"
        bordered
        style={{
          marginBottom: "1em" /* TODO: Remover no mobile */,
        }}
      >
        {tableItems ? (
          <>
            {tableItems.headers && tableItems.headers.length ? (
              <thead>
                <tr>
                  {tableItems.headers.map((item, index) => (
                    <th key={index}>{item}</th>
                  ))}
                </tr>
              </thead>
            ) : (
              ""
            )}
            {tableItems.body ? (
              <tbody>
                {histories && histories.length
                  ? histories.map((history, index) => {
                      return (
                        <tr key={index}>
                          {tableItems.body && tableItems.body.length
                            ? tableItems.body.map((item, index) => {
                                if (item.indexOf(".") !== -1) {
                                  // Is an Object. Need to access the object's property
                                  const objectName = item.split(".")[0];
                                  const propertyName = item.split(".")[1];
                                  if (item.indexOf("|ARRAY|") !== -1) {
                                    // Is an Array of objects. Need to access each array's object's property
                                    item = item.replace("|ARRAY|", "");
                                    const objectName = item.split(".")[0];
                                    const propertyName = item.split(".")[1];
                                    const arrayItems = history[objectName].map(
                                      (arrayItem) => arrayItem[propertyName]
                                    );
                                    return (
                                      <td>
                                        {arrayItems && arrayItems.length
                                          ? arrayItems.map((arrayItem, idx) => {
                                              if (propertyName === "file") {
                                                return (
                                                  <tr key={idx}>
                                                    <a
                                                      href={`${filesURL}/${arrayItem}`}
                                                      target="_blank"
                                                    >
                                                      {arrayItem}
                                                    </a>
                                                  </tr>
                                                );
                                              }
                                              return (
                                                <tr key={idx}>{arrayItem}</tr>
                                              );
                                            })
                                          : null}
                                      </td>
                                    );
                                  }
                                  return (
                                    <td key={index}>
                                      {history[objectName][propertyName]}
                                    </td>
                                  );
                                }
                                return <td key={index}>{history[item]}</td>;
                              })
                            : ""}
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </Table>
      {/* </CardBody>
      </Card> */}
    </Collapse>
  );
};

export default EventsDetails;

function consoleLogWithColor(message, color, withBackground) {
  let colorHex = "";
  switch (color) {
    case "red":
      colorHex = "#aa0000";
      break;
    case "yellow":
      colorHex = "#bada00";
      break;
    case "green":
      colorHex = "#22aa22";
      break;
    case "blue":
      colorHex = "#2222aa";
      break;
  }
  console.log(
    `%c${message}`,
    `
      color: ${colorHex};
      font-size: 14px;
      ${withBackground ? "background-color: rgba(0,0,0,.9);" : ""}
    `
  );
}
