import React from "react";

import { FaFile, FaFilePdf, FaFileExcel, FaFileWord } from "react-icons/fa";

import { FileInfo } from "./styles";

const FileItem = ({ fileUploaded, file, onDelete }) => (
  <>
    <FileInfo>
      <div>
        {file.indexOf(".pdf") !== -1 && <FaFilePdf />}
        {file.indexOf(".xls") !== -1 && <FaFileExcel />}
        {file.indexOf(".doc") !== -1 && <FaFileWord />}
        {file.indexOf(".pdf") === -1 &&
          file.indexOf(".xls") === -1 &&
          file.indexOf(".doc") === -1 && <FaFile />}
        {fileUploaded && (
          <a href={`localhost:5100/files/${file}`} target="_blank">
            <strong title={file}>{file}</strong>
          </a>
        )}
        {!fileUploaded && <strong title={file}>{file}</strong>}
      </div>
      <button type="button" onClick={() => onDelete(file.id)}>
        Excluir
      </button>
    </FileInfo>
  </>
);

export default FileItem;
